/* You can add global styles to this file, and also import other style files */
.swal-index{
  z-index:99999 !important;
}

img[alt] {
  font-size: 17px;
}
.tooltip-custom-class .tooltip-inner {
  font-size: 1.2rem !important;
}
.tooltip{
  opacity: 1 !important;
}
.tooltip-custom-class .arrow::before {
border-top-color: #b00032;
}